<template>
  <div class="app">
    <router-view />

    <van-back-top offset="400" bottom="15vh" @click="onBackTop"></van-back-top>

    <FixedBackHome v-show="showFixedBackHome"></FixedBackHome>

    <!-- 答题插屏 -->
    <WapInter v-if="showAnswerInter" space-key="AnswerInter"></WapInter>
  </div>
</template>

<script lang="ts" setup>
import FixedBackHome from "./components/common/FixedBackHome.vue";
import WapInter from "@hnw/nw-sdk/components/wap-inter.vue";

import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { Haa, OeReportType, OeReportSubType } from "@hnw/nw-sdk";
import { useControlStore } from "@/store/control";
import { storeToRefs } from "pinia";

const route = useRoute();

// 是否展示返回首页全局浮窗按钮
const showFixedBackHome = computed<boolean>(() => {
  // 刷新后 route.matched第一时间是空数组
  let flag = !!route.matched?.[0];

  // 判断路由表上是否携带隐藏返回首页icon字段
  if (route.matched?.[0]?.meta.hasOwnProperty("hideFixedHome")) {
    flag = route.matched[0].meta.hideFixedHome as boolean;
  }

  return flag;
});

// 根据答题数 是否展示插屏
const { showAnswerInter } = storeToRefs(useControlStore());

// 返回顶部按钮点击 收集行为
const onBackTop = () => {
  const { oeReport } = Haa.util().useOeReport();

  if (!route.name) return;

  oeReport(OeReportType.Action, OeReportSubType.ActionCollect, route.name as string);
};
</script>

<style lang="less">
.app {
  width: 750px;
  min-height: 100vh;
  margin: 0 auto;
}

.van-back-top {
  background-color: #04ce7d !important;
}
</style>
